import { Action } from 'redux';

import { LoadDocument } from 'shared/models/load-document.model';
import { ExtendedLoad } from 'shared/models/loads/extended-load.model';

export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_ERROR = 'FETCH_DOCUMENTS_ERROR';
export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';

export interface FetchDocumentsAction extends Action {
  load: ExtendedLoad;
}
export interface FetchDocumentsSuccessAction extends Action {
  payload: LoadDocument[];
}

export interface FetchDocumentsErrorAction extends Action {
  error: Error;
}

export const fetchDocumentsSuccess = (payload: LoadDocument[]): FetchDocumentsSuccessAction => ({type: FETCH_DOCUMENTS_SUCCESS, payload});
export const fetchDocumentsError = (error: Error): FetchDocumentsErrorAction => ({type: FETCH_DOCUMENTS_ERROR, error});
export const fetchDocuments = (load: ExtendedLoad): FetchDocumentsAction => ({type: FETCH_DOCUMENTS, load});
